import React from "react"
import { Link } from "gatsby"
import "../components/layout.scss"

import ChangePassword from "../components/ChangePassword"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PasswordRecoveryPage = ({ location }) => (
  <Layout location={location}>
    <ChangePassword />
  </Layout>
)

export default PasswordRecoveryPage