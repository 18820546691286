import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, message } from 'antd'
import { navigate } from 'gatsby';

const ChangePassword = () => {
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        const urlParams = new URLSearchParams(window.location.search);
        values['reset_password_token'] = urlParams.get('reset_password_token');
        values['redirect_url'] = urlParams.get('redirect_url');

        setLoading(true)
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        };

        fetch('https://center.vivosalud.org/auth/password', requestOptions)
            .then(response => response.json())
            .then(response => {
                setLoading(false)
                if (response.success) {
                    navigate('/password/recovery/success')
                } else {
                    message.error(response.errors[0], 10)
                }
            })
    }

    return (
        <>
            <Row justify="center" align="middle" style={{ position: 'relative', zIndex: 300, height: 'calc(100vh - 80px)' }}>
                <Col lg={8}>
                    <div style={{ padding: 20 }}>
                        <Form layout="vertical" onFinish={onFinish} hideRequiredMark>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <h1 style={{ color: 'white', fontWeight: 'bold' }}>Cambiar Contraseña</h1>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        rules={[
                                            { required: true, message: 'Debes ingresar una contraseña' },
                                            { min: 8, message: 'La contraseña debe tener al menos 8 caracteres' }
                                        ]}
                                    >
                                        <Input.Password size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="password_confirmation"
                                        label="Confirmar Contraseña"
                                        rules={[
                                            { required: true, message: 'Debes confirmar tu contraseña' },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('¡Las dos contraseñas que ingresaste no coinciden!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password size={'large'} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button loading={loading} type="primary" htmlType="submit" size={'large'} style={{ width: '100%', height: 60, borderRadius: 10 }}>
                                            Cambiar Contraseña
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ChangePassword;